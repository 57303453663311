import { useCallback, useMemo, useReducer, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { handleFileUpload } from "Api/Pages/cloudinaryAssetUpload";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  Row,
} from "Components/Core";
import AssetTags from "Components/Shared/AssetTags";

import // isFileSizeValid,

"Api/Pages/cloudinaryAssetUpload";
import {
  assetTagsReducer,
  defaultAssetTagsData,
} from "Components/Shared/AssetTags/utils";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,

  // SizeConfigType,
  UPLOAD_PURPOSE_ENUM,
} from "./types";

import "./styles.scss";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";

interface IProps {
  isModalVisible: boolean;

  title: string;
  assetType?: ASSET_TYPE_ENUM;
  //sizeConfig: SizeConfigType;

  purpose: UPLOAD_PURPOSE_ENUM;
  sportsList?: Array<{
    id: number;
    image_link: string;
    name: string;
  }>;
  toggleModal: VoidFunction;
  onAssetSubmitCallback: (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => void;
}

const UploadAsset = (props: IProps) => {
  const {
    isModalVisible,
    title = "Upload Asset",
    purpose,
    toggleModal,
    onAssetSubmitCallback,
  } = props;

  const {
    formState: { errors },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm<IAssetDetails>({
    defaultValues: {
      thumbnail_url: "",
      kpoint_gcc_id: "",
      description: "",
      title: "",
      asset: null,
      sport: "",
    },
  });

  const initialAssetTagsData = useMemo(() => defaultAssetTagsData(), []);
  const [assetTagsData, assetTagsDispatch] = useReducer(
    assetTagsReducer,
    initialAssetTagsData
  );
  const [assetTagsError, setAssetTagsError] = useState<string | null>(null);

  // const [uploadError, setUploadError] = useState<string | null>(null);
  const [objectKey, setObjecyKey] = useState<string | null>(null);

  const validAssetTagsData = (): string | null => {
    if (
      purpose === UPLOAD_PURPOSE_ENUM.drill &&
      !assetTagsData.master_sport_id
    ) {
      return "Sport is required";
    }

    return null;
  };
  const uploadType = watch("of_type", ASSET_TYPE_ENUM.image);
  useEffect(() => {
    setValue("of_type", ASSET_TYPE_ENUM.image);
  }, [setValue]);
  const onSubmit = (data: any) => {
    if (purpose === UPLOAD_PURPOSE_ENUM.public) {
      return [
        onAssetSubmitCallback(
          { ...data, purpose, object_key: objectKey },
          assetTagsData
        ),
        toggleModal(),
      ];
    }

    const assetError = validAssetTagsData();
    setAssetTagsError(assetError);

    if (!assetError) {
      onAssetSubmitCallback({ ...data, purpose }, assetTagsData);
      toggleModal();
    }
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    if (purpose !== UPLOAD_PURPOSE_ENUM.public) {
      setAssetTagsError(validAssetTagsData());
    }

    handleSubmit(onSubmit)(e);
  };

  const assetTagsDispatchWrapper = useCallback(
    (action: { type: string; payload: any }) => {
      setAssetTagsError(null);
      assetTagsDispatch(action);
    },
    []
  );

  return (
    <Modal centered size="lg" show={isModalVisible}>
      <ModalHeader>{title}</ModalHeader>

      <Form className="d-flex flex-column scroll-y" onSubmit={handleFormSubmit}>
        <ModalBody>
          <Row>
            <Col sm={8}>
              <Form.Group controlId="textarea">
                <Form.Label className="fs-xs">Title*</Form.Label>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      className="border"
                      placeholder="Start typing title here"
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Group controlId="textarea">
                <Form.Label className="fs-xs">Description*</Form.Label>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Description is required" }}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className="border"
                      placeholder="Tell us more about the video"
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Conditional Section for Public Purpose */}
          {purpose === UPLOAD_PURPOSE_ENUM.public && (
            <>
              <Row>
                <Col sm={8}>
                  <Form.Group controlId="uploadType">
                    <Form.Label>Select Upload Type</Form.Label>
                    <Controller
                      name="of_type"
                      control={control}
                      defaultValue={uploadType}
                      render={({ field }) => (
                        <Form.Control as="select" {...field}>
                          <option value="image">Upload Image</option>
                          <option value="video">Upload Video</option>
                        </Form.Control>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Conditionally render fields based on upload type */}
              {watch("of_type") === "image" && (
                <Row>
                  <Col sm={8}>
                    <Form.Group controlId="image">
                      <Form.Label>Upload Image</Form.Label>
                      <Controller
                        name="image"
                        control={control}
                        rules={{ required: "Image is required" }}
                        render={({ field }) => (
                          <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={async (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const file = e.target.files
                                ? e.target.files[0]
                                : null;

                              if (file) {
                                field.onChange(file);

                                const objectKey = await handleFileUpload(file);
                                setObjecyKey(objectKey);
                              }
                            }}
                          />
                        )}
                      />
                      {errors.image && (
                        <Form.Text className="text-danger">
                          {errors.image.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {watch("of_type") === "video" && (
                <>
                  <Row>
                    <Col sm={8}>
                      <Form.Group controlId="kpoint_gcc_id">
                        <Form.Label>GCC ID</Form.Label>
                        <Controller
                          name="kpoint_gcc_id"
                          control={control}
                          rules={{ required: "GCC ID is required" }}
                          render={({ field }) => (
                            <Form.Control
                              type="text"
                              className="border"
                              placeholder="Enter GCC ID"
                              {...field}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <Form.Group controlId="thumbnail_url">
                        <Form.Label>Thumbnail URL</Form.Label>
                        <Controller
                          name="thumbnail_url"
                          control={control}
                          rules={{ required: "Thumbnail URL is required" }}
                          render={({ field }) => (
                            <Form.Control
                              type="text"
                              className="border"
                              placeholder="Enter Thumbnail URL"
                              {...field}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {/* Fields and Tags for Non-Public Purposes */}
          {purpose !== UPLOAD_PURPOSE_ENUM.public && (
            <>
              <Row>
                <Col sm={8}>
                  <Form.Group controlId="kpoint_gcc_id">
                    <Form.Label>GCC ID</Form.Label>
                    <Controller
                      name="kpoint_gcc_id"
                      control={control}
                      rules={{ required: "GCC ID is required" }}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          className="border"
                          placeholder="Enter GCC ID"
                          {...field}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <Form.Group controlId="thumbnail_url">
                    <Form.Label>Thumbnail URL</Form.Label>
                    <Controller
                      name="thumbnail_url"
                      control={control}
                      rules={{ required: "Thumbnail URL is required" }}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          className="border"
                          placeholder="Enter Thumbnail URL"
                          {...field}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <AssetTags
                    formData={assetTagsData}
                    dispatch={assetTagsDispatchWrapper}
                    disabled={false}
                  />
                </Col>
              </Row>
              {assetTagsError && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {assetTagsError}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-secondary"
            size="sm"
            className="px-4"
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button variant="secondary" size="sm" className="px-4" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UploadAsset;
