import { ButtonIcon } from "Components/Shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { EMPTY_LABEL } from "App/constants";
import TextWrapper from "Components/Shared/TextWrapper";
import { formatDate, formatTimestamp } from "Utils/helper";

export const getAssetColumns = (
  onDeleteRowClicked: Function,
  shouldAllowDelete: boolean
) => {
  const tableColumns = [
    {
      dataField: "video",
      title: "Video",
      colWidth: "20%",
      formatter: (data: AssetType) => {
        const { thumbnail_url } = data;
        const { is_active } = data;

        return (
          <div className="d-flex align-items-center">
            <div
              className={`border-start border-4 ${
                is_active ? "border-success" : "border-danger"
              }`}
              style={{ width: "10px", height: "40px" }}
            ></div>
            <div className="thumbnail-box position-relative">
              <img src={thumbnail_url} alt={`video thumbnail ${data.title}`} />
              <FontAwesomeIcon
                icon={faPlay}
                color="white"
                className="position-absolute fs-5 icon-media"
              />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "title",
      title: "Name",
      colWidth: "30%",
    },
    {
      dataField: "description",
      title: "Description",
      colWidth: "30%",
      formatter: (data: AssetType) => {
        return data.description ? data.description : EMPTY_LABEL;
      },
    },
    {
      dataField: "kpoint_gcc_id",
      title: "KPOINT GCC ID",
      colWidth: "60%",
    },
    {
      dataField: "uploaded_by",
      title: "Uploaded By",
      colWidth: "10%",
    },
    {
      dataField: "sport",
      title: "Sports",
      colWidth: "30%",
      formatter: (data: AssetType) => {
        return data.master_sport_name ? data.master_sport_name : EMPTY_LABEL;
      },
    },
    {
      dataField: "created_at",
      title: "Created at",
      colWidth: "10%",
      formatter: (data: AssetType) => {
        return data.created_at ? (
          <TextWrapper width={120} tooltipText={formatDate(data.created_at)}>
            {formatTimestamp(data.created_at)}
          </TextWrapper>
        ) : (
          EMPTY_LABEL
        );
      },
    },
  ];

  if (shouldAllowDelete) {
    tableColumns.push({
      dataField: "delete",
      title: "Action",
      colWidth: "10%",
      formatter: (data: AssetType) => {
        return (
          <div className="d-flex">
            <ButtonIcon
              onClick={(event) => {
                event.stopPropagation();
                onDeleteRowClicked(data.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonIcon>
          </div>
        );
      },
    });
  }
  return tableColumns;
};
