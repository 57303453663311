import axios from "axios";
import { GetListParam } from "Interfaces/Components/Shared/DisplayListComponentInterface";
import { getListURL } from "Components/Shared/DisplayListComponent/constants";

export const getList = async ({
  perPage, //Controls the number of items retrieved per request.
  userType,
  academyId,
  searchInput,
  selectValue,
  //currentPage,
  active,
  sort_column,
  order_by,
  matchPlayedUserType,
  id,
  pageParam = 1, // Tracks the current page for infinite scroll.
}: GetListParam & { pageParam?: number }) => {
  const URL = getListURL({
    perPage,
    academyId,
    searchInput,
    selectValue,
    currentPage: pageParam,
    active,
    sort_column,
    order_by,
    matchPlayedUserType,
    id,
  })[userType];

  const { data } = await axios.get(URL);
  if (data) {
    return data;
  }

  return [];
};
